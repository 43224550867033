



























































































import { Component, Watch, Prop, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  Col,
  Row,
  Button,
  NavBar,
  Field,
  Icon,
  Dialog,
  Popup,
  Picker,
} from "vant";
import { isPhoneNum, loginCommon } from "@/utils/index";
import UserCheck from "@/views/Login/UserCheck.vue";
import { SlideCheck } from "@/types";

@Component({
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    UserCheck,
  },
})
export default class MobileBond extends Mixins(Mixin) {
  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  openId!: string;
  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  unionId!: string;

  public isFirstSend = true;
  public loginDisabled = true;
  public reciveCode = "获取验证码";
  public hideshow = true; //显示或隐藏footer
  public docmHeight = document.documentElement.clientHeight; //默认屏幕高度
  public showHeight = document.documentElement.clientHeight; //实时屏幕高度
  public userCheckShow = false;
  public showPrivacy = false;
  public form = {
    phone: "",
    code: "",
    phoneCode: "",
    phoneArea: "86",
    token: "",
  };
  public showPicker = false;
  public phoneColumns = [
    {
      text: "",
      key: "",
    },
  ];
  public columns = [
    {
      dictId: "5199110600000000003",
      dictClsId: "1099110500000000059",
      dictCode: "86",
      dictName: "中国大陆",
      dictDesp: "",
      showNo: null,
      isSystem: null,
    },
    {
      dictId: "5199110600000000004",
      dictClsId: "1099110500000000059",
      dictCode: "852",
      dictName: "中国香港",
      dictDesp: "",
      showNo: null,
      isSystem: null,
    },
    {
      dictId: "5199110600000000005",
      dictClsId: "1099110500000000059",
      dictCode: "853",
      dictName: "中国澳门",
      dictDesp: "",
      showNo: null,
      isSystem: null,
    },
    {
      dictId: "5199110600000000006",
      dictClsId: "1099110500000000059",
      dictCode: "886",
      dictName: "中国台湾",
      dictDesp: "",
      showNo: null,
      isSystem: null,
    },
  ];
  public countdown = {
    num: 60,
    status: false,
  };
  public redirect = "/mine";
  public userCheck = null as null | UserCheck;
  public checkModel: SlideCheck | undefined = undefined;
  get isPhoneNum(): boolean {
    return isPhoneNum(this.form.phone, this.form.phoneArea);
  }
  @Watch("form.phoneCode")
  cloginDisabled(): void {
    if (this.form.phoneCode) {
      if (this.isPhoneNum && this.form.phoneCode.length == 6) {
        this.loginDisabled = false;
      } else {
        this.loginDisabled = true;
      }
    } else {
      this.loginDisabled = true;
    }
  }
  @Watch("form.phone")
  changePhone(): void {
    this.checkModel = undefined;
    if (this.form.phoneCode) {
      if (this.isPhoneNum && this.form.phoneCode.length == 6) {
        this.loginDisabled = false;
      } else {
        this.loginDisabled = true;
      }
    } else {
      this.loginDisabled = true;
    }
  }

  mounted(): void {
    // window.onresize监听页面高度的变化,防止底部图标上来
    window.onresize = () => {
      return (() => {
        this.showHeight = document.body.clientHeight;
        if (this.docmHeight > this.showHeight) {
          this.hideshow = false;
        } else {
          this.hideshow = true;
        }
      })();
    };

    this.$nextTick(() => {
      this.userCheck = this.$refs["user-check"] as UserCheck;
    });
  }

  created(): void {
    //手机号区号
    this.columns.forEach((item) => {
      this.phoneColumns.push({
        text: `${item.dictName} +${item.dictCode}`,
        key: item.dictCode,
      });
    });
    //上次登录存的手机号自动填充
    this.form.phone = !localStorage.getItem("lastPhoneNum")
      ? ""
      : JSON.parse(JSON.stringify(localStorage.getItem("lastPhoneNum")));
  }
  hfocus(type: "mobile" | "code"): void {
    if (type === "mobile") {
      this.$refs["minpt"] && (this.$refs["minpt"] as HTMLElement).focus();
    } else {
      this.$refs["cinpt"] && (this.$refs["cinpt"] as HTMLElement).focus();
    }
  }
  onClickLeft(): void {
    this.$router.go(-1);
  }
  closeLogin(): void {
    this.$router.go(-1);
  }
  // 修改手机区号
  onConfirm(value: { key: string }): void {
    this.form.phoneArea = value.key;
    this.changePickerStatus();
  }
  // 手机区号选择器打开&关闭
  changePickerStatus(): void {
    this.showPicker = !this.showPicker;
  }
  // 手机号验证规则
  checkPhoneNum(): boolean {
    if (this.isPhoneNum) {
      return true;
    } else {
      if (!this.form.phone) {
        this.$toast("手机号输入不能为空");
        return false;
      } else {
        this.$toast("请输入正确的手机号");
        return false;
      }
    }
  }
  checkPhoneCode(): boolean {
    if (this.form.phoneCode) {
      if (this.form.phoneCode.length == 6) {
        return true;
      } else {
        this.$toast("请输入正确的验证码");
        return false;
      }
    } else {
      this.$toast("请输入正确的验证码");
      return false;
    }
  }

  //人机校验对话框确定
  getUserToken(data: any): void {
    let phoneCaptchaRequest = {
      account: this.form.phone,
      phoneArea: "+" + this.form.phoneArea,
      sessionId: "",
      sig: "",
      token: "",
      captchaRequest: data,
    };
    this.$api.memberApi.cuUser.getMemberCaptcha(
      phoneCaptchaRequest,
      () => {
        this.isFirstSend = false;
        if (!this.isFirstSend) {
          this.reciveCode = "再次获取";
        }
        this.$toast("验证码已发送，请注意查收！");
        this.countDown();
      },
      (err) => {
        this.$toast(err);
        if (err === "滑动验证结果不正确") {
          setTimeout(() => {
            this.openUserCheck();
          }, 1000);
        }
      }
    );
  }

  // 发送短信验证码
  sendCodeMessage(): void {
    if (this.reciveCode == "再次获取") {
      this.form.phoneCode = "";
    }
    if (this.checkPhoneNum()) {
      this.openUserCheck();
    }
  }

  /**
   * 打开滑块验证码
   */
  openUserCheck(): void {
    if (this.userCheck) {
      this.userCheck.show();
    }
  }

  // 验证码发送倒计时
  countDown(): void {
    if (this.countdown.num === 0) {
      this.countdown.status = false;
      this.countdown.num = 60;
      window.nc.reset();
      return;
    }
    this.countdown.status = true;
    this.countdown.num--;

    setTimeout(() => {
      this.countDown();
    }, 1000);
  }

  // 绑定
  submitLogin(): void {
    this.$api.oauth2Api.socialLogin.bind(
      {
        channel: "MP", // 渠道
        clientId: "",
        clientSecret: "",
        code: this.form.phoneCode, // 验证码
        phoneArea: this.form.phoneArea, // 区号
        mobile: this.form.phone, // 手机号
        openid: this.openId,
        unionid: this.unionId,
      },
      ({ data }) => {
        loginCommon(data, this.$api);
        let routeRecordModule = this.RouteRecordModule;
        let beforeLoginPage = routeRecordModule.beforeLoginPage;
        if (beforeLoginPage) {
          this.$router.replace({
            path: beforeLoginPage.path,
            query: beforeLoginPage.query,
          });
        } else {
          /**
           * 如果登录前没有路由记录，那么说明登录前不在当前单页应用中
           * 此时倒退就会来到应用外的页面，因此这里重定向到首页
           */
          this.$router.replace("/home");
        }
      }
    );
  }
}
